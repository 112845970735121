<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '@/helpers/errorHandler';
import { required, integer } from 'vuelidate/lib/validators'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// import { Quill } from 'vue-quill-editor';
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);

/**
 * Starter component
 */
export default {
  page: {
    title: `Question Form`,
  },
  data() {
    return {
      title: "Questions",
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Homeworks",
          href: "/homeworks",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        question_text: "",
        ans_one: "",
        ans_two: "",
        ans_three: "",
        ans_four: "",
        ans_five: "",
        right_ans: "",
        grade: 0,
        quiz_id: this.$route.params.quiz
      },
      answerOptions:[
        {
          text:'First answer',
          value: 'ans_one'
        },
        {
          text:'Second answer',
          value: 'ans_two'
        },
        {
          text:'Third answer',
          value: 'ans_three'
        },
        {
          text:'Forth answer',
          value: 'ans_four'
        },
        {
          text:'Fifth answer',
          value: 'ans_five'
        },

      ],
      answerError: null,
      editorOption: {
        // Some Quill options...
        theme: "snow",
        modules: {
          // ImageResize: {
          //   modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
          // },
          toolbar: [
            [
              {
                font: [],
              },
              {
                size: [],
              },
            ],
            ["bold", "italic", "underline", "strike"],
            [
              {
                color: [],
              },
              {
                background: [],
              },
            ],
            [
              {
                script: "super",
              },
              {
                script: "sub",
              },
            ],
            [
              {
                header: [false, 1, 2, 3, 4, 5, 6],
              },
              "blockquote",
              "code-block",
            ],
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
              {
                indent: "-1",
              },
              {
                indent: "+1",
              },
            ],
            [
              "direction",
              {
                align: [],
              },
            ],
            ["link", "image", "video"],
            ["clean"],
          ],
        },
      },
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations:{
    form:{
      question_text: {
        required,
      },
      grade:{
        integer,
        required
      },
      // ans_one:{
      //   required,
      // },
      // ans_two:{
      //   required,
      // },
    }
  },
  methods:{
    validate(){
      if(this.form.right_ans){
        this.answerError = true;
      }else{
        this.answerError = false;
      }
      return this.answerError;
    },
    clearSelectError3(){
      this.answerError = null;
    },
    async handleSubmit() {
      this.submit = true;
      let check = await this.validate();
      this.$v.$touch();
      if(!this.$v.$anyError && check){
        this.$store.dispatch("homework/addQuestion",this.form).then(
           ()=>{
            errorHandler.methods.successful(`Question has been created successfully!`)
            this.$router.push(`/homeworks/${this.$route.params.quiz}/details`);
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
    async handleUpdate(){
      this.submit = true;
      let check = await this.validate();
      this.$v.$touch();
      if(!this.$v.$anyError && check){
        this.$store.dispatch("homework/editQuestion",this.form).then(
           ()=>{
            errorHandler.methods.successful(`Question has been created successfully!`)
            this.$router.push(`/homeworks/${this.$route.params.quiz}/details`);
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
  },
  async created(){
    if(this.$route.params.id){
      this.type = 'edit';
      await this.$store.dispatch('homework/getQuestion',this.$route.params.id).then( res =>{
        console.log(res)
        this.form.question_text = res.question_text;
        this.form.ans_one = res.ans_one;
        this.form.ans_two = res.ans_two;
        this.form.ans_three = res.ans_three;
        this.form.ans_four = res.ans_four;
        this.form.ans_five = res.ans_five
        this.form.right_ans = res.right_ans;
        this.form.grade =  res.grade;
        this.form.quiz_id = this.$route.params.quiz
        this.form.id = this.$route.params.id;
      }).catch(err =>{
        errorHandler.methods.errorMessage(err);
      })
    }else{
      this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create Question</h2>
                    <h2 v-if="type == 'edit'">Edit Question</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Question head:"
                        label-for="input-D"
                    >
                        <quill-editor v-model="form.question_text" style="min-height: 200px" :options="editorOption"  :class="{ 'is-invalid': submit && $v.form.question_text.$error }"/>
                        <!-- <input v-model="form.question_text" type="text" name="question_text" class="form-control" :class="{ 'is-invalid': submit && $v.form.question_text.$error }" placeholder="Enter question here" /> -->
                        <div v-if="submit && $v.form.question_text.$error" class="invalid-feedback">
                            <span v-if="!$v.form.question_text.required">This head is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="First answer:"
                        label-for="input-D"
                    >
                        <quill-editor v-model="form.ans_one" style="min-height: 200px" :options="editorOption"  />
                        <!-- <input v-model="form.ans_one" type="text" name="ans_one" class="form-control" :class="{ 'is-invalid': submit && $v.form.ans_one.$error }" placeholder="Enter answer here" /> -->
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Second answer:"
                        label-for="input-D"
                    >
                        <quill-editor v-model="form.ans_two" style="min-height: 200px" :options="editorOption"  />
                        <!-- <input v-model="form.ans_two" type="text" name="ans_two" class="form-control" :class="{ 'is-invalid': submit && $v.form.ans_two.$error }" placeholder="Enter answer here" /> -->
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Third answer:"
                        label-for="input-D"
                    >
                    <quill-editor v-model="form.ans_three" style="min-height: 200px" :options="editorOption"/>
                        <!-- <input v-model="form.ans_three" type="text" name="ans_three" class="form-control" placeholder="Enter answer here" /> -->
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Forth answer:"
                        label-for="input-D"
                    >
                        <quill-editor v-model="form.ans_four" style="min-height: 200px" :options="editorOption"/>
                        <!-- <input v-model="form.ans_four" type="text" name="ans_four" class="form-control" placeholder="Enter answer here" /> -->
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Fifth answer:"
                        label-for="input-D"
                    >
                        <quill-editor v-model="form.ans_five" style="min-height: 200px" :options="editorOption"/>
                        <!-- <input v-model="form.ans_five" type="text" name="ans_five" class="form-control" placeholder="Enter answer here" /> -->
                    </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group label="Right choice:" label-for="input-C" >
                        <b-form-select  v-if="type == 'create' " v-model="form.right_ans" :options="this.answerOptions" :state="answerError" @change="clearSelectError3"></b-form-select>
                        <b-form-select  v-if="type == 'edit' && form.right_ans " v-model="form.right_ans" :options="this.answerOptions" :state="answerError" @change="clearSelectError3"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>